<br-navbar [logoName]="project" logoLink="/"
  ><br-menu navigation-menu navigationMenu="true">
    <br-menu-item icon="home" route="/home">{{ project }}</br-menu-item>
    <br-menu-item icon="list" route="/woningaanbod">Woningaanbod</br-menu-item>
    <br-menu-item icon="calendar" route="">Planning</br-menu-item>
    <br-menu-item icon="envelope" route="">contact</br-menu-item>
    <br-menu-item icon="pencil" route="">Inschrijven</br-menu-item>
  </br-menu></br-navbar
>
<router-outlet></router-outlet>
