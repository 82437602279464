import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";

@Component({
  selector: "app-woningen",
  templateUrl: "./woningen.component.html",
  styleUrls: ["./woningen.component.scss"]
})
export class WoningenComponent implements OnInit {
  project = "Demo Project";
  constructor(private $route: ActivatedRoute, public router: Router) {
    this.$route.queryParams.subscribe((params: Params) => {
      if (params.project) {
        this.project = params.project;
      }
    });
  }

  ngOnInit(): void {}

  selectBuildNumber(e) {
    console.log(e);
    if (e.bouwnummer < 10) {
      this.router.navigate(["bouwnummer-1"]);
    } else {
      this.router.navigate(["bouwnummer-10"]);
    }
  }

  indicatorState(state) {
    switch (state) {
      case "Beschikbaar":
        return "success";
      case "Onder optie":
        return "warning";
      case "Verkocht":
        return "alert";
    }
  }

  woningen = [
    {
      bouwnummer: 1,
      status: "Beschikbaar",
      type: "A",
      koopsom: "€380.000",
      woonoppervlakte: "100 m²",
      slaapkamers: 2,
      open: ""
    },
    {
      bouwnummer: 2,
      status: "Beschikbaar",
      type: "A",
      koopsom: "€380.000",
      woonoppervlakte: "100 m²",
      slaapkamers: 2,
      open: ""
    },
    {
      bouwnummer: 3,
      status: "Onder optie",
      type: "A",
      koopsom: "€380.000",
      woonoppervlakte: "100 m²",
      slaapkamers: 2,
      open: ""
    },
    {
      bouwnummer: 4,
      status: "Verkocht",
      type: "A",
      koopsom: "€380.000",
      woonoppervlakte: "100 m²",
      slaapkamers: 2,
      open: ""
    },
    {
      bouwnummer: 5,
      status: "Beschikbaar",
      type: "A",
      koopsom: "€380.000",
      woonoppervlakte: "100 m²",
      slaapkamers: 2,
      open: ""
    },
    {
      bouwnummer: 7,
      status: "Onder optie",
      type: "A",
      koopsom: "€400.000",
      woonoppervlakte: "100 m²",
      slaapkamers: 2,
      open: ""
    },
    {
      bouwnummer: 8,
      status: "Verkocht",
      type: "A",
      koopsom: "€400.000",
      woonoppervlakte: "100 m²",
      slaapkamers: 2,
      open: ""
    },
    {
      bouwnummer: 9,
      status: "Onder optie",
      type: "A",
      koopsom: "€4200.000",
      woonoppervlakte: "100 m²",
      slaapkamers: 2,
      open: ""
    },
    {
      bouwnummer: 10,
      status: "Beschikbaar",
      type: "B",
      koopsom: "€579.000",
      woonoppervlakte: "143 m²",
      slaapkamers: 2,
      open: ""
    },
    {
      bouwnummer: 11,
      status: "Beschikbaar",
      type: "B",
      koopsom: "€600.000",
      woonoppervlakte: "143 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 12,
      status: "Verkocht",
      type: "B",
      koopsom: "€600.000",
      woonoppervlakte: "143 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 13,
      status: "Beschikbaar",
      type: "B",
      koopsom: "€600.000",
      woonoppervlakte: "143 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 14,
      status: "Onder optie",
      type: "B",
      koopsom: "€600.000",
      woonoppervlakte: "143 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 15,
      status: "Onder optie",
      type: "B",
      koopsom: "€600.000",
      woonoppervlakte: "143 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 16,
      status: "Beschikbaar",
      type: "B",
      koopsom: "€600.000",
      woonoppervlakte: "143 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 17,
      status: "Beschikbaar",
      type: "C",
      koopsom: "€600.000",
      woonoppervlakte: "157 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 18,
      status: "Beschikbaar",
      type: "C",
      koopsom: "€610.000",
      woonoppervlakte: "157 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 19,
      status: "Beschikbaar",
      type: "C",
      koopsom: "€610.000",
      woonoppervlakte: "157 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 20,
      status: "Verkocht",
      type: "C",
      koopsom: "€625.000",
      woonoppervlakte: "157 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 21,
      status: "Beschikbaar",
      type: "C",
      koopsom: "€625.000",
      woonoppervlakte: "157 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 22,
      status: "Onder optie",
      type: "C",
      koopsom: "€625.000",
      woonoppervlakte: "157 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 23,
      status: "Beschikbaar",
      type: "C",
      koopsom: "€625.000",
      woonoppervlakte: "157 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 24,
      status: "Beschikbaar",
      type: "C",
      koopsom: "€625.000",
      woonoppervlakte: "157 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 25,
      status: "Onder optie",
      type: "D",
      koopsom: "€800.000",
      woonoppervlakte: "170 m²",
      slaapkamers: 4,
      open: ""
    }
  ];
}
