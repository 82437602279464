import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./pages/home/home.component";
import { WoningenComponent } from "./pages/woningen/woningen.component";
import { TypeAComponent } from "./pages/type-a/type-a.component";
import { TypeBComponent } from "./pages/type-b/type-b.component";
import { Bn1Component } from "./pages/bn1/bn1.component";
import { Bn2Component } from "./pages/bn2/bn2.component";

const routes: Routes = [
  { path: "home", component: HomeComponent },
  { path: "woningaanbod", component: WoningenComponent },
  { path: "type-a", component: TypeAComponent },
  { path: "type-b", component: TypeBComponent },
  { path: "bouwnummer-1", component: Bn1Component },
  { path: "bouwnummer-10", component: Bn2Component },
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "**", redirectTo: "" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
