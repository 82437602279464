import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-type-a",
  templateUrl: "./type-a.component.html",
  styleUrls: ["./type-a.component.scss"]
})
export class TypeAComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit(): void {}

  indicatorState(state) {
    switch (state) {
      case "Beschikbaar":
        return "success";
      case "Onder optie":
        return "warning";
      case "Verkocht":
        return "alert";
    }
  }

  woningen = [
    {
      bouwnummer: 1,
      status: "Beschikbaar",
      type: "A",
      koopsom: "€380.000",
      woonoppervlakte: "100 m²",
      slaapkamers: 2,
      open: ""
    },
    {
      bouwnummer: 2,
      status: "Beschikbaar",
      type: "A",
      koopsom: "€380.000",
      woonoppervlakte: "100 m²",
      slaapkamers: 2,
      open: ""
    },
    {
      bouwnummer: 3,
      status: "Onder optie",
      type: "A",
      koopsom: "€380.000",
      woonoppervlakte: "100 m²",
      slaapkamers: 2,
      open: ""
    },
    {
      bouwnummer: 4,
      status: "Verkocht",
      type: "A",
      koopsom: "€380.000",
      woonoppervlakte: "100 m²",
      slaapkamers: 2,
      open: ""
    },
    {
      bouwnummer: 5,
      status: "Beschikbaar",
      type: "A",
      koopsom: "€380.000",
      woonoppervlakte: "100 m²",
      slaapkamers: 2,
      open: ""
    },
    {
      bouwnummer: 7,
      status: "Onder optie",
      type: "A",
      koopsom: "€400.000",
      woonoppervlakte: "100 m²",
      slaapkamers: 2,
      open: ""
    },
    {
      bouwnummer: 8,
      status: "Verkocht",
      type: "A",
      koopsom: "€400.000",
      woonoppervlakte: "100 m²",
      slaapkamers: 2,
      open: ""
    },
    {
      bouwnummer: 9,
      status: "Onder optie",
      type: "A",
      koopsom: "€4200.000",
      woonoppervlakte: "100 m²",
      slaapkamers: 2,
      open: ""
    }
  ];
}
