import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-type-b",
  templateUrl: "./type-b.component.html",
  styleUrls: ["./type-b.component.scss"]
})
export class TypeBComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit(): void {}

  indicatorState(state) {
    switch (state) {
      case "Beschikbaar":
        return "success";
      case "Onder optie":
        return "warning";
      case "Verkocht":
        return "alert";
    }
  }

  woningen = [
    {
      bouwnummer: 10,
      status: "Beschikbaar",
      type: "B",
      koopsom: "€579.000",
      woonoppervlakte: "143 m²",
      slaapkamers: 2,
      open: ""
    },
    {
      bouwnummer: 11,
      status: "Beschikbaar",
      type: "B",
      koopsom: "€600.000",
      woonoppervlakte: "143 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 12,
      status: "Verkocht",
      type: "B",
      koopsom: "€600.000",
      woonoppervlakte: "143 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 13,
      status: "Beschikbaar",
      type: "B",
      koopsom: "€600.000",
      woonoppervlakte: "143 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 14,
      status: "Onder optie",
      type: "B",
      koopsom: "€600.000",
      woonoppervlakte: "143 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 15,
      status: "Onder optie",
      type: "B",
      koopsom: "€600.000",
      woonoppervlakte: "143 m²",
      slaapkamers: 3,
      open: ""
    },
    {
      bouwnummer: 16,
      status: "Beschikbaar",
      type: "B",
      koopsom: "€600.000",
      woonoppervlakte: "143 m²",
      slaapkamers: 3,
      open: ""
    }
  ];
}
