import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import * as moment from "moment";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  project = "Demo Project";
  nextWeek: string;
  yesterday: string;
  tenDaysAgo: string;

  constructor(private $route: ActivatedRoute, public router: Router) {
    this.$route.queryParams.subscribe((params: Params) => {
      if (params.project) {
        this.project = params.project;
      }
    });
  }

  ngOnInit(): void {
    let date = new Date();
    moment.locale("nl");
    this.nextWeek = moment(date.getTime() + 7 * 24 * 60 * 60 * 1000).format(
      "D MMMM"
    );
    this.yesterday = moment(date.getTime() - 24 * 60 * 60 * 1000).format(
      "dddd, D MMMM YYYY"
    );
    this.tenDaysAgo = moment(date.getTime() - 10 * 24 * 60 * 60 * 1000).format(
      "dddd, D MMMM YYYY"
    );
  }
}
