<br-hero
  full="true"
  [scrolls]="true"
  image="/assets/images/hero.jpg"
  [colorize]="false"
  backgroundColor="var(--gray-50)"
>
  <div class="dark">
    <h1
      style="font-size: 6rem; text-shadow: 0.25rem 0.25rem 0.25rem black"
      class="brand"
    >
      {{ project }}
    </h1>
    <br-button icon="pencil" size="full" type="primary" href="/home#inschrijven"
      >Inschrijven</br-button
    >
  </div>
</br-hero>

<br-page-layout brandIdentity="true">
  <br-section layout="left">
    <h2>Stijlvol wonen in {{ project }}</h2>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet quod culpa
      recusandae est beatae, dolor ab reprehenderit nemo a quam alias cum, harum
      et, totam cumque facilis aperiam. Autem, id. Lorem ipsum dolor sit amet,
      consectetur adipisicing elit. Aspernatur corporis soluta facere dolorum
      quae hic debitis iure, itaque voluptate consequuntur vero repudiandae nam
      sed, unde ab cupiditate? Quod, dicta, enim!
    </p>

    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non quasi
      suscipit expedita nulla ducimus laborum veritatis libero dolor labore,
      rerum eius iure recusandae saepe, quam. Nobis enim, vero sapiente
      dignissimos.
    </p>
  </br-section>

  <br-section background="url(/assets/images/ext5.jpg)">
    <div class="dark">
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
    </div>
  </br-section>

  <br-section>
    <h2>De koopwoningen van {{ project }}</h2>
    <br-flex spacing="var(--big)">
      <br-card
        title="Type A"
        image="/assets/images/int1.jpg"
        meta="Appartement"
        clickable="true"
        size="wide"
        (click)="router.navigate(['type-a'])"
      >
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        </p>

        <p class="flush">
          <br-grid
            columns="max-content auto"
            columnGap="var(--regular)"
            rowGap="var(--small)"
          >
            <br-icon name="money-bill-wave" color="var(--gray-40)"></br-icon>
            <span>€380.000 - €420.00</span>

            <br-icon name="home" color="var(--gray-40)"></br-icon>
            <span>Appartement</span>

            <br-icon name="expand" color="var(--gray-40)"></br-icon>
            <span>100 m²</span>

            <br-icon name="bed" color="var(--gray-40)"></br-icon>
            <span>2 slaapkamers</span>
          </br-grid>
        </p>
      </br-card>

      <br-card
        title="Type B"
        image="/assets/images/int2.jpg"
        meta="Appartement"
        clickable="true"
        size="wide"
        (click)="router.navigate(['type-b'])"
      >
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        </p>

        <p class="flush">
          <br-grid
            columns="max-content auto"
            columnGap="var(--regular)"
            rowGap="var(--small)"
          >
            <br-icon name="money-bill-wave" color="var(--gray-40)"></br-icon>
            <span>€579.000 - €600.000</span>

            <br-icon name="home" color="var(--gray-40)"></br-icon>
            <span>Appartement</span>

            <br-icon name="expand" color="var(--gray-40)"></br-icon>
            <span>143 m²</span>

            <br-icon name="bed" color="var(--gray-40)"></br-icon>
            <span>3 slaapkamers</span>
          </br-grid>
        </p>
      </br-card>
    </br-flex>

    <br-flex spacing="var(--big)">
      <br-card
        title="Type C"
        image="/assets/images/int3.jpg"
        meta="Appartement"
        clickable="true"
        size="wide"
      >
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        </p>

        <p class="flush">
          <br-grid
            columns="max-content auto"
            columnGap="var(--regular)"
            rowGap="var(--small)"
          >
            <br-icon name="money-bill-wave" color="var(--gray-40)"></br-icon>
            <span>€600.000 - €625.000</span>

            <br-icon name="home" color="var(--gray-40)"></br-icon>
            <span>Appartement</span>

            <br-icon name="expand" color="var(--gray-40)"></br-icon>
            <span>157 m²</span>

            <br-icon name="bed" color="var(--gray-40)"></br-icon>
            <span>3 slaapkamers</span>
          </br-grid>
        </p>
      </br-card>

      <br-card
        title="Type D"
        image="/assets/images/ext6.jpg"
        meta="Appartement"
        clickable="true"
        size="wide"
      >
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        </p>

        <p class="flush">
          <br-grid
            columns="max-content auto"
            columnGap="var(--regular)"
            rowGap="var(--small)"
          >
            <br-icon name="money-bill-wave" color="var(--gray-40)"></br-icon>
            <span>€870.000</span>

            <br-icon name="home" color="var(--gray-40)"></br-icon>
            <span>Appartement</span>

            <br-icon name="expand" color="var(--gray-40)"></br-icon>
            <span>170 m²</span>

            <br-icon name="bed" color="var(--gray-40)"></br-icon>
            <span>4 slaapkamers</span>
          </br-grid>
        </p>
      </br-card>
    </br-flex>

    <br-stack size="big"></br-stack>

    <br-flex justify="center">
      <br-button icon="arrow-right" type="tertiary">Alle woningen</br-button>
    </br-flex>
  </br-section>

  <br-section background="url(/assets/images/int1.jpg)">
    <div class="dark">
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
      <br-stack size="huge"></br-stack>
      <h1
        style="font-size: 4rem; line-height: 4rem; text-shadow: 0.5rem 0.5rem 2rem var(--gray-80)"
      >
        Start verkoop {{ nextWeek }}
      </h1>
    </div>
  </br-section>

  <br-section>
    <h2>{{ project }} nieuws</h2>
    <br-flex spacing="var(--big)">
      <br-card
        title="Verkoop datum bekend"
        image="/assets/images/ext1.jpg"
        [meta]="yesterday"
        clickable="true"
        size="wide"
      >
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        </p>
      </br-card>

      <br-card
        title="Nieuwe impressies"
        image="/assets/images/int3.jpg"
        [meta]="tenDaysAgo"
        clickable="true"
        size="wide"
      >
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        </p>
      </br-card>
    </br-flex>

    <br-stack size="big"></br-stack>

    <br-flex justify="center">
      <br-button icon="caret-down" type="tertiary">Meer nieuws</br-button>
    </br-flex>
  </br-section>

  <br-section background="url(/assets/images/ext3.jpg)" layout="split">
    <div class="dark" left>
      <br-stack size="huge"></br-stack>
      <h1
        style="font-size: 4rem; line-height: 4rem; text-shadow: 0.5rem 0.5rem 2rem var(--gray-80)"
      >
        Schrijf je in voor de {{ project }} nieuwsbrief
      </h1>
    </div>

    <div id="inschrijven" right>
      <br-stack size="huge"></br-stack>
      <app-form></app-form>
    </div>
  </br-section>

  <br-section>
    <h2>{{ project }} locatie</h2>

    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Exercitationem
      debitis sit labore, doloremque excepturi ad, quas accusantium nihil
      quisquam iure laboriosam nisi autem neque? Deleniti cupiditate hic quasi
      eos expedita.
    </p>
    <div class="mapouter">
      <div class="gmap_canvas">
        <iframe
          width="100%"
          height="400"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=Tiendweg%202C%20Naaldwijk&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>
      <style>
        .mapouter {
          position: relative;
          text-align: right;
          height: 500px;
          width: 100%;
        }
        .gmap_canvas {
          overflow: hidden;
          background: none !important;
          height: 500px;
          width: 100%;
        }
      </style>
    </div>
  </br-section>
</br-page-layout>
