import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bn2',
  templateUrl: './bn2.component.html',
  styleUrls: ['./bn2.component.scss']
})
export class Bn2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
