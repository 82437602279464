<br-hero
  image="/assets/images/int2.jpg"
  [colorize]="false"
  backgroundColor="var(--gray-50)"
>
  <br-flex class="dark" spacing="var(--huge)" alignment="center">
    <br-flex-item>
      <h1>Woningtype B</h1>
    </br-flex-item>

    <br-grid
      columns="max-content auto"
      columnGap="var(--regular)"
      rowGap="var(--small)"
      class="summary"
    >
      <br-icon name="money-bill-wave" color="var(--gray-0)"></br-icon>
      <span>€579.000</span>

      <br-icon name="home" color="var(--gray-0)"></br-icon>
      <span>Appartement</span>

      <br-icon name="expand" color="var(--gray-0)"></br-icon>
      <span>143 m²</span>

      <br-icon name="bed" color="var(--gray-0)"></br-icon>
      <span>2 slaapkamers</span>
    </br-grid>
  </br-flex>
</br-hero>

<br-page-layout brandIdentity="true">
  <br-section layout="split">
    <div left>
      <h2>Omschrijving</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quae ad, iusto
        repellendus, natus facere incidunt dolores, voluptatem quod repellat
        similique rem earum eum fugit in quaerat nemo quidem omnis deserunt.
      </p>
    </div>

    <div right>
      <br-image path="/assets/images/ext4.jpg" containImage="true"></br-image>
    </div>
  </br-section>

  <br-section>
    <h2>Bouwnummer 10</h2>
    <br-image path="/assets/images/type-b.jpg" containImage="true"></br-image>
  </br-section>
</br-page-layout>
