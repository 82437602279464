import { Component, ViewEncapsulation, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: [
    "./app.component.scss",
    "../../node_modules/@real-tech/brick/main.css"
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = "demoproject-nl";
  project = "Demo Project";

  constructor(private $route: ActivatedRoute) {
    this.$route.queryParams.subscribe((params: Params) => {
      if (params.project) {
        this.project = params.project;
      }
    });
  }

  ngOnInit() {}
}
