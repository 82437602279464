import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";

import { BrickModule, MessageModule } from "@real-tech/brick";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./pages/home/home.component";
import { ReactiveFormsModule } from "@angular/forms";
import { FormComponent } from "./components/form/form.component";
import { TypeAComponent } from "./pages/type-a/type-a.component";
import { TypeBComponent } from "./pages/type-b/type-b.component";
import { Bn1Component } from "./pages/bn1/bn1.component";
import { Bn2Component } from "./pages/bn2/bn2.component";
import { WoningenComponent } from './pages/woningen/woningen.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FormComponent,
    TypeAComponent,
    TypeBComponent,
    Bn1Component,
    Bn2Component,
    WoningenComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BrickModule,
    ReactiveFormsModule,
    MessageModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
