import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import axios from "axios";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"]
})
export class FormComponent implements OnInit {
  myForm: FormGroup;
  url = "https://us-central1-demoproject-nl.cloudfunctions.net/handleForm";
  success = false;
  error = false;
  loading = false;

  constructor() {
    this.myForm = new FormGroup(
      {
        name: new FormControl("", [Validators.required]),
        email: new FormControl("", [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ])
      },
      { updateOn: "change" }
    );
  }

  ngOnInit(): void {}

  disabled() {
    let disabled = true;

    if (this.myForm.valid && this.myForm.touched) disabled = false;

    return disabled;
  }

  sendForm() {
    let form = document.getElementById("inschrijven_form");
    console.log("sending...");
    this.loading = true;
    axios
      .post(this.url, {
        name: this.myForm.get("name").value,
        email: this.myForm.get("email").value
      })
      .then(res => this.handleResponse(res))
      .catch(err => console.log(err));
  }

  handleResponse(res) {
    console.log(res);
    this.loading = false;
    if (res.data == "success") {
      this.success = true;
    } else {
      this.error = true;
    }
  }
}
