<div class="form">
  <form
    [formGroup]="myForm"
    *ngIf="!success"
    id="inschrijven_form"
    (ngSubmit)="sendForm()"
  >
    <h3>Aanmelden</h3>
    <br-message title="Oeps" type="alert" *ngIf="error">
      Er is iets fout gegaan, probeer het over enekele ongenblikken opnieuw aub.
    </br-message>
    <br-input
      label="Naam"
      type="text"
      name="name"
      [messages]="{
        required: 'Geef een naam op'
      }"
    ></br-input>
    <br-input
      label="e-mail"
      type="email"
      name="email"
      [messages]="{
        required: 'Vul je email adres in',
        pattern: 'Dit is geen geldig email adres'
      }"
    ></br-input>
    <br-button
      icon="pencil"
      type="primary"
      [submit]="true"
      [disabled]="disabled()"
      [loading]="loading"
      >Inschrijven</br-button
    >
  </form>
  <div *ngIf="success">
    <h3>Gelukt!</h3>
    <p>
      Je bent nu ingeschreven voor onze nieuwsbrief, we houden je op de hoogte
      van de laatste ontwikkelingen met betrekking tot dit project.
    </p>
  </div>
</div>
